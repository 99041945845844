<template>
  <!-- 注册页面 -->
  <div class="register">
    <div class="header">
      <img id="logo" src="../assets/logo3.png" />
    </div>
    <van-field
      class="input"
      ref="phone"
      id="phone"
      type="number"
      v-model="form.phone"
      label="手机号"
      placeholder="请输入手机号"
      :error="error.phone.error"
      :error-message="error.phone.msg"
    />
    <van-field
      class="input"
      ref="smsCode"
      id="smsCode"
      type="number"
      v-model="form.smsCode"
      center
      clearable
      label="短信验证码"
      placeholder="请输入短信验证码"
      maxlength="6"
      :error="error.smsCode.error"
      :error-message="error.smsCode.msg"
    >
      <van-button
        slot="button"
        size="small"
        type="warning"
        :disabled="smsCodeOK"
        @click="sendCode"
      >{{smsBtnText}}</van-button>
    </van-field>
    <van-field
      class="input"
      ref="password"
      id="password"
      v-model="form.password"
      type="password"
      maxlength="16"
      label="密码"
      placeholder="请输入密码"
      :error="error.password.error"
      :error-message="error.password.msg"
    />
    <van-field id="recommendCode" v-model="form.recommendCode" type="hidden" />
    <div class="subBtn">
      <van-button slot="button" size="large" type="warning" @click="submit">注册</van-button>
    </div>

    <div class="bottom">
      <div class="recommend">
        <van-tag round type="warning">{{this.form.recommendCode}}({{recommendName}})</van-tag>
      </div>
      <router-link class="appdown" to="/download">下载应用</router-link>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { getRecommendInfo, sendSmsCode, registerUser } from "@/api/user";
export default {
  name: "Register",
  data() {
    return {
      show: false,
      phoneRex: /^(?:(?:\+|00)86)?1\d{10}$/, // 手机号码正则
      smsCodeOK: false, // 短信是否发送
      smsTime: 120, // 每次发送短信的间隔时间
      smsBtnText: "发送验证码",
      recommendName: "", // 推荐人名称
      form: {
        phone: "",
        smsCode: "",
        password: "",
        recommendCode: ""
      },
      error: {
        phone: { error: false, msg: "" },
        smsCode: { error: false, msg: "" },
        password: { error: false, msg: "" }
      }
    };
  },
  beforeRouteEnter(to, from, next) {
    getRecommendInfo(to.params.code)
      .then(res => {
        console.log("res = ", res);
        if (res.code === -1) {
          next("/404");
        }
        if (res && res.code === 0) {
          const data = res.data;
          let recommendName = "";
          if (data.name && data.name.length >= 3) {
            recommendName = "**" + data.name.substring(data.name.length - 1);
          } else if (data.name) {
            recommendName = "*" + data.name.substring(data.name.length - 1);
          }

          next(vm => vm.setRecommendName(recommendName));
          console.log("recommendName  = ", recommendName);
        }
      })
      .catch(error => {
        console.log("error = ", error);
      });
    // next();
  },
  created() {
    this.form.recommendCode = this.$route.params.code;
  },
  methods: {
    submit() {
      if (!this.phoneRex.test(this.form.phone)) {
        this.error.phone.msg = "请输入正确的手机号码";
        this.error.phone.error = true;
        return;
      }
      if (
        !this.form.smsCode ||
        this.form.smsCode === "" ||
        this.form.smsCode.length < 6
      ) {
        this.error.smsCode.msg = "请输入正确的短信验证码";
        this.error.smsCode.error = true;
        return;
      }

      if (!this.form.password || this.form.password === "") {
        this.error.password.msg = "请输入密码";
        this.error.password.error = true;
        return;
      }
      if (this.form.password.length < 6) {
        this.error.password.msg = "请输入6位以上的密码";
        this.error.password.error = true;
        return;
      }
      for (const key in this.error) {
        this.error[key].error = false;
        this.error[key].msg = null;
      }
      const router = this.$router;
      registerUser(this.form).then(res => {
        if (res && res.code === 0) {
          Toast("注册成功!");
          router.push({ path: "/download" });
        } else if (res) {
          Toast(res.msg);
        }
      });
    },
    test(e) {
      window.console.log("submit = ", e.target.id);
    },
    sendCode() {
      if (this.smsCodeOK) return;
      if (!this.phoneRex.test(this.form.phone)) {
        this.error.phone.msg = "请输入正确的手机号码";
        this.error.phone.error = true;
        return;
      } else {
        this.error.phone.msg = null;
        this.error.phone.error = false;
      }
      let time = this.smsTime;
      sendSmsCode(this.form.phone, 1).then(res => {
        if (res && res.code === 0) {
          this.smsCodeOK = true;
          time--;
          this.smsBtnText = time + "S";
          Toast("短信发送成功");
          const inv = setInterval(() => {
            time--;
            this.smsBtnText = time + "S";
            if (time === 0) {
              clearInterval(inv);
              this.smsCodeOK = false;
              this.smsBtnText = "发送验证码";
            }
          }, 1000);
        } else if (res) {
          Toast(res.msg);
          this.smsCodeOK = false;
        }
      });
    },
    setRecommendName(name) {
      this.recommendName = name;
      console.log("this.recommendName =", this.recommendName);
    }
  }
};
</script>

<style lang="scss">
.register {
  // padding: 10px 0px;
  // background: #f7f7f7;
  height: 100%;
}
.header {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 30px;
}
.bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  .appdown {
    margin-top: 20px;
  }
}
.subBtn {
  padding: 0 30px;
}
.input {
  // margin-bottom: 10px;
}
.van-field__label {
  // font-size: 15px;
}
#logo {
  width: 100%;
  height: 80px;
}
</style>
