import request from "@/common/request.js";

export function getInfo() {
  return request({
    url: "/app/statistics/info",
    method: "post"
  });
}

export function getAllData(params) {
  return request({
    url: "/app/v2/statistic/allData",
    method: "post",
    data: JSON.stringify(params)
  });
}
