<template>
  <div class="page">
    <van-loading v-if="isLoad" style="margin-top:30px;" size="24" vertical>加载中</van-loading>
    <div class="statistics">
      <!-- 统计数量 -->
      <div class="stat-list">
        <div
            class="stat"
            v-for="(item,index) in stat"
            @click="selectStat(item.id)"
            :key="index"
            :class="{'stat-select': item.isSelect}"
            :style="{background: item.color}"
        >
          <div class="num">{{ item.num }}</div>
          <div class="text">{{ item.text }}</div>
        </div>
      </div>
      <!-- 选择日和月 -->
      <div class="select">
        <div class="select-item-list">
          <div
              class="item"
              :class="{'item-active':selectStatMode == 1 }"
              @click="changeStatMode(1, statData)"
          >日
          </div>
          <div
              class="item"
              :class="{'item-active':selectStatMode == 2 }"
              @click="changeStatMode(2, statData)"
          >月
          </div>
        </div>
      </div>
      <!-- 折线图 -->
      <div class="chart">
        <v-chart ref="stat" :options="statCount" @click="statClick"/>
      </div>
      <!-- 数据展示 -->
      <div class="count-list">
        <div class="count-item count-item-right">
          <div class="count">{{ statItem.total }}</div>
          <div class="text" v-if="statIndex != 1">新增总数</div>
          <div class="text" v-if="statIndex == 1">新增激活</div>
        </div>
        <div class="count-item count-item-right">
          <div class="count">{{ statItem.teamAdd }}</div>
          <div class="text">团队新增</div>
        </div>
        <div class="count-item">
          <div class="count">{{ statItem.myAdd }}</div>
          <div class="text" v-if="statIndex != 1">直营新增</div>
          <div class="text" v-if="statIndex == 1">直营激活</div>
        </div>
      </div>

      <div
          class="pos-active-count"
          v-if="statIndex == 'TERMINAL'"
      >出货量 {{ statItem.shipCount }}台
      </div>

      <div
          class="pos-active-count"
          v-if="statIndex == 'TERMINAL'"
      >终端激活总数 {{ statData.terminalAcCount }} 台
      </div>
    </div>

    <div class="transaction">

      <div class="topTitle">
        <div class="title">交易数据</div>
        <div class="btnData" @click="goAllData()">所有数据 ></div>
      </div>
      <!-- 交易数据量 -->
      <div class="count">
        <div class="top">
          <div class="text">共交易{{ tranItem.tranCount }}笔,合计(万元)</div>
          <div class="select-item-list">
            <div
                class="item"
                :class="{'item-active':selectTranMode == 1 }"
                @click="changeTranMode(1)"
            >日
            </div>
            <div
                class="item"
                :class="{'item-active':selectTranMode == 2 }"
                @click="changeTranMode(2)"
            >月
            </div>
          </div>
        </div>
        <div class="tran-amt">{{ tranItem.total }}</div>
        <!-- <div class="hq">环球增长 0%</div> -->
      </div>
      <!-- 交易统计表 -->
      <div class="chart">
        <v-chart :options="tranCount" v-on:click="tranClick"/>
      </div>
      <!-- 总数据展示 -->
      <div class="count-list">
        <div class="count-item count-item-right">
          <div class="count">{{ tranItem.teamTotal }}</div>
          <div class="text">团队合计(万元)</div>
        </div>
        <div class="count-item">
          <div class="count">{{ tranItem.myTotal }}</div>
          <div class="text">直营合计(万元)</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/chart/line";
import "echarts/lib/component/polar";
import {getInfo} from "@/api/statistics";

export default {
  name: "Statistics",
  components: {
    "v-chart": ECharts
  },

  data() {
    return {
      selectStatMode: 2, // 1是日 2是月 团队统计选择
      selectTranMode: 2, // 1是日 2是月 交易数据统计选择
      statIndex: "TEAM", // stat数据默认坐标0
      isLoad: false,
      stat: [
        {
          id: "TEAM",
          text: "团队",
          color: "#F96543",
          num: 0,
          isSelect: true,
          monthData: [],
          dayData: []
        },
        {
          id: "TERMINAL",
          text: "终端",
          color: "#469BFB",
          num: 0,
          isSelect: false,
          monthData: [],
          dayData: [],
          terminalAcCount: 0 // 总激活数量
        },
        {
          id: "MERCHANT",
          text: "商户",
          color: "#FBB753",
          num: 0,
          isSelect: false,
          monthData: [],
          dayData: []
        }
      ],
      statCount: {
        // 团队和终端和商户统计数据表
        color: "#F96543",
        tooltip: {
          trigger: "axis",
          formatter: function () {
            // 不限时提示信息
            return "";
          },
          axisPointer: {
            show: false
          }
        },
        grid: {
          top: "20px",
          left: "30px",
          right: "30px",
          bottom: "20px",
          containLabel: true
        },
        // legend: {
        //   data: ["最高气温", "最低气温"]
        // },
        toolbox: {
          show: false,
          feature: {
            dataZoom: {
              yAxisIndex: "none"
            },
            dataView: {readOnly: false},
            magicType: {type: ["line", "bar"]},
            restore: {},
            saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          triggerEvent: true, // 坐标轴的标签是否响应和触发鼠标事件，默认不响应。
          boundaryGap: false,
          splitLine: {
            show: false
          },
          axisTick: {
            show: false // y轴刻度线
          },
          axisPointer: {
            show: false, // 不限时选择数据时的坐标线
            lineStyle: {
              color: "transparent" // 透明颜色
            }
          },
          data: []
        },
        yAxis: {
          type: "value",
          axisLabel: {
            show: false, // 不显示坐标轴上的标签
            formatter: ""
          },
          axisTick: {
            show: false // y轴刻度线
          },
          splitLine: {
            show: false // 网格线
          },
          axisLine: {
            show: false // 不显示坐标轴线
          }
        },
        series: [
          {
            name: "数量",
            type: "line",
            smooth: true, // 折点是圆弧状的
            showSymbol: true,
            // symbol: "circle", //折点设定为实心点
            symbolRotate: 333,
            symbolSize: 20, // 设定实心点的大小
            areaStyle: {
              // normal: {
              //   color: "#469BFB" //改变区域颜色
              // }
            },
            itemStyle: {
              // normal: {
              //   color: "#469BFB", //改变折线点的颜色
              //   borderColor: "#469BFB",
              //   lineStyle: {
              //     color: "#469BFB" //改变折线颜色
              //   }
              // }
            },
            data: []
          }
        ]
        // animationDuration: 1000
      },
      tranCount: {
        // 交易数据统计表
        color: ["#F96543"],
        tooltip: {
          trigger: "axis",
          formatter: function () {
            // 不限时提示信息
            return "";
          },
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          top: "20px",
          left: "25px",
          right: "25px",
          bottom: "20px",
          containLabel: true
        },
        xAxis: {
          type: "category",
          splitLine: {
            show: false
          },
          axisPointer: {
            // show: false, // 不限时选择数据时的坐标线
            lineStyle: {
              color: "#469BFB"
            }
          },
          axisTick: {
            show: false, // y轴刻度线
            alignWithLabel: true
          },
          data: []
        },
        yAxis: {
          type: "value",
          axisLabel: {
            show: false // 不显示坐标轴上的标签
          },
          axisTick: {
            show: false // y轴刻度线
          },
          splitLine: {
            show: false // 网格线
          },
          axisLine: {
            show: false // 不显示坐标轴线
          }
        },
        series: [
          {
            name: "数据",
            type: "bar",
            barWidth: "60%",
            smooth: true, // 折点是圆弧状的
            data: []
          }
        ]
      },
      tranData: {
        // 交易数据
        monthData: [],
        dayData: []
      },
      statItem: {}, // 统计选中的展示数据 // 默认当前月份/当天的数据
      tranItem: {} // 交易数据统计展示的数据
    };
  },
  // beforeRouteEnter(to, from, next) {
  //   // let vueData = this.data;
  //
  //   // next();
  // },
  created() {
    this.isLoad = true;
    getInfo().then(res => {
      const data = res.data;
      this.initData(data);
      this.isLoad = false;
    });
    // this.logger.log("xxx= ");
  },
  methods: {
    // 初始化信息
    initData(data) {
      // window.console.log("data = ", data);
      const team = this.getStatById("TEAM");
      const terminal = this.getStatById("TERMINAL");
      const merchant = this.getStatById("MERCHANT");
      // 团队
      team.num = data.teamCount;
      team.monthData = data.teamMonthDetails;
      team.dayData = data.teamDayDetails;
      // 终端
      terminal.num = data.terminalCount;
      terminal.monthData = data.terminalMonthDetails;
      terminal.dayData = data.terminalDayDetails;
      terminal.terminalAcCount = data.terminalAcCount;
      // 商户
      merchant.num = data.merchantCount;
      merchant.monthData = data.merchantMonthDetails;
      merchant.dayData = data.merchantDayDetails;
      this.tranData.monthData = data.tranMonthDetails;
      this.tranData.dayData = data.tranDayDetails;
      // window.console.log("statData = ", this.statData);
      this.changeStatMode(this.selectStatMode, this.statData);
      this.changeTranMode(this.selectTranMode);
    },
    getStatById(id) {
      return this.stat.filter(item => item.id === id)[0];
    },
    selectStat(id) {
      for (const item of this.stat) {
        item.isSelect = false;
      }

      for (const item of this.stat) {
        if (item.id === id) {
          item.isSelect = true;
          this.statIndex = item.id;
          this.statCount.color = item.color;
        }
      }

      this.changeStatMode(this.selectStatMode, this.statData);
    },
    changeStatMode(mode, info) {
      // window.console.log("info =", info);
      this.selectStatMode = mode;
      if (mode === 1) {
        // 日
        const label = [];
        const data = [];
        for (const item of info.dayData) {
          label.push(item.label);
          data.push(item.total);
        }
        // let day = ["27日", "28日", "29日", "30日", "31日", "1日", "2日"];
        this.statCount.xAxis.data = label;
        this.statCount.series[0].data = data;
        this.statItem = info.dayData[info.dayData.length - 1];
      } else {
        // 月
        const label = [];
        const data = [];
        for (const item of info.monthData) {
          // window.console.log("item = ", item.label);
          label.push(item.label);
          data.push(item.total);
        }
        // window.console.log("label = ", label);
        // window.console.log("data =", data);
        // let month = ["08月", "09月", "10月", "11月", "12月", "01月", "02月"];
        this.statCount.xAxis.data = label;
        this.statCount.series[0].data = data;
        this.statItem = info.monthData[info.monthData.length - 1];
        // window.console.log("this.statItem =", this.statItem);
      }
    },
    changeTranMode(mode) {
      this.selectTranMode = mode;

      if (mode === 1) {
        // 日
        const label = [];
        const data = [];
        for (const item of this.tranData.dayData) {
          // window.console.log("item = ", item.label);
          label.push(item.label);
          data.push(item.total);
        }
        // let day = ["27日", "28日", "29日", "30日", "31日", "1日", "2日"];
        this.tranCount.xAxis.data = label;
        this.tranCount.series[0].data = data;
        this.tranItem = this.tranData.dayData[this.tranData.dayData.length - 1];
      } else {
        // window.console.log(
        //   "this.tranData.monthData =",
        //   this.tranData.monthData
        // );
        // 月
        const label = [];
        const data = [];
        for (const item of this.tranData.monthData) {
          // window.console.log("item = ", item.label);
          label.push(item.label);
          data.push(item.total);
        }
        // let month = ["08月", "09月", "10月", "11月", "12月", "01月", "02月"];
        this.tranCount.xAxis.data = label;
        this.tranCount.series[0].data = data;
        this.tranItem = this.tranData.monthData[
        this.tranData.monthData.length - 1
            ];
      }
    },
    statClick(data) {
      // window.console.log("test = ", data);
      const index = data.dataIndex; // 数据坐标
      if (this.selectStatMode === 1) {
        // 日
        this.statItem = this.statData.dayData[index];
      } else {
        // 月
        this.statItem = this.statData.monthData[index];
      }
      console.log('statItem =', this.statItem)
    },
    tranClick(data) {
      window.console.log("test = ", data);
      const index = data.dataIndex; // 数据坐标
      if (this.selectTranMode === 1) {
        // 日
        this.tranItem = this.tranData.dayData[index];
        // window.console.log("this.tranItem = ", this.tranItem);
      } else {
        // 月
        this.tranItem = this.tranData.monthData[index];
      }
    },
    goAllData() {
      this.$router.push({path: '/allData'})
    }
  },
  computed: {
    statData() {
      return this.stat.filter(item => item.id === this.statIndex)[0];
    }
  },
  mounted: function () {
    // window.console.log("$ref = ", this.$refs.stat.chart);
    // let aa = [
    //   "dblclick",
    //   "mousedown",
    //   "mousemove",
    //   "mouseup",
    //   "mouseover",
    //   "mouseout",
    //   "globalout",
    //   "contextmenu"
    // ];
    // for (const item of aa) {
    //   this.$refs.stat.chart.on(item, function(data) {
    //     window.console.log("data =", data);
    //   });
    // }
    // this.$refs.stat.chart.on('click','xAxis', function(data) {
    //     window.console.log("data =", data);
    //   });
  }
};
</script>

<style lang="scss" scoped>
.statistics {
  background: #fff;
  margin-bottom: 10px;

  .stat-list {
    // background: red;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;

    .stat {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 70px;
      width: 70px;
      border-radius: 100%;
      background: #333;
      color: #fff;
      font-size: 13px;

      .num {
        margin-bottom: 5px;
      }

      .text {
      }
    }

    .stat:nth-child(2) {
      margin-left: 15px;
      margin-right: 15px;
    }

    .stat-select {
      height: 90px;
      width: 90px;
      font-size: 16px;
      transition: 1s ease;
    }
  }

  .pos-active-count {
    text-align: center;
    line-height: 40px;
    font-size: 11px;
    color: rgb(128, 127, 127);
  }
}

.select {
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
}

.select-item-list {
  display: inline-flex;
  background: #f0f0f0;
  padding: 2px;
  border-radius: 5px;
}

.item {
  padding: 5px 20px 5px 20px;
}

.item-active {
  background: #fff;
  border-radius: 5px;
}

.transaction {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  background: #fff;

  .topTitle {
    //padding-left: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .btnData {

  }

  .title {
    // text-align: left;
    font-size: 20px;
    line-height: 65px;
    color: #000;
  }

  .count {
    //padding: 0 20px;
    display: flex;
    flex-direction: column;

    .top {
      display: flex;
      justify-content: space-between;
    }

    .tran-amt {
      font-size: 28px;
      text-align: left;
    }

    .hq {
      font-size: 12px;
      margin-top: 5px;
      color: rgb(124, 124, 124);
    }
  }
}

.chart {
  height: 200px;
}

.echarts {
  width: 100%;
  height: 100%;
}

.count-list {
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  .count-item {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .text {
      color: #a5a4a4;
      font-size: 12px;
    }
  }

  .count-item-right {
    border-right: 1px solid #a5a4a4;
  }
}
</style>
