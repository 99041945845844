import request from "@/common/request";

export function getRecommendInfo(code) {
  return request({
    url: `/api/public/validRecommendCode/${code}`,
    method: "post"
  });
}

export function sendSmsCode(phone, type) {
  const data = {
    phone: phone,
    type: type
  };
  return request({
    url: "/app/user/sendCode",
    method: "post",
    data: data
  });
}
export function test() {
  const data = {
    id: "111",
    username: "2222"
  };
  return request({
    url: "http://localhost:8181/user",
    method: "post",
    data: JSON.stringify(data)
  });
}

export function registerUser({ phone, smsCode, recommendCode, password }) {
  const data = {
    phone: phone,
    smsCode: smsCode,
    recommendCode: recommendCode,
    password: password
  };
  return request({
    url: "/app/v2/user/h5/reg",
    method: "post",
    data: JSON.stringify(data)
  });
}
