<template>
  <div style="height: 100%;  background-color: #f8f8f8;">
    <van-nav-bar title="所有数据" left-arrow fixed @click-left="goBack()" />
    <div class="main">
      <div class="pdd15px" style="margin-bottom: 15px">
        <div style="margin-top: 60px; background: #fff; border-radius: 10px;">
          <van-row
            type="flex"
            justify="space-between"
            style="padding: 10px 15px"
          >
            <van-col span="10" @click="showSelectType = !showSelectType"
              >{{ selectPosType[selectPosIndex] }}
              <van-icon name="arrow-down" />
            </van-col>
            <van-col span="14" style="font-size: 15px;text-align: right;">
              <span @click="selectDate(0)">{{ startTime || "开始时间" }}</span
              >&nbsp; -&nbsp;
              <span @click="selectDate(1)">{{ endTime || "结束时间" }}</span>
            </van-col>
          </van-row>
          <div class="total-amt">
            <div class="txt">交易总金额(万元)</div>
            <div class="amt">{{ result.totalAmt }}</div>
            <div style="margin-top: 10px" @click="loadData"><van-icon name="replay" />刷新</div>
          </div>
        </div>
      </div>

      <div class="pdd15px" style="border-radius: 10px;background: #fff;margin: 0 15px;">
        <van-cell-group title="交易明细">
          <van-cell title="自营交易金额(元)" :value="result.myAmt" />
          <van-cell title="横排交易金额(元)" :value="result.hpAmt" />
          <van-cell title="团队交易金额(元)" :value="result.teamAmt" />
        </van-cell-group>
      </div>
    </div>
    <!-- <van-button type="danger">危险按钮</van-button> -->

    <van-popup v-model="showDate" position="bottom">
      <van-datetime-picker
        type="date"
        :title="dateTxt"
        @cancel="showDate = false"
        v-model="currentDate"
        :max-date="maxDate"
        :formatter="formatterDate"
        @confirm="confirmDate"
      />
    </van-popup>

    <van-popup v-model="showSelectType" position="bottom">
      <van-picker
        title="选择机具类型"
        show-toolbar
        :columns="selectPosType"
        @confirm="onPosType"
        @cancel="showSelectType = false"
      />
    </van-popup>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import { getPosType } from "@/api/posType";
import { getAllData } from "@/api/statistics";
import dayjs from "dayjs";
import { Toast } from "vant";
export default {
  name: "AllData",
  components: {},
  data() {
    return {
      maxDate: new Date(),
      startTime: null,
      endTime: null,
      selectPosIndex: 0,
      selectPosType: ["全部机具"],
      selectPosTypeId: [0],
      showSelectType: false,
      showDate: false,
      dateTxt: "",
      currentDate: new Date(),
      selectDateType: 0,
      result: {
        totalAmt: "0.00", // 交易总金额
        myAmt: "0.00", // 自营交易金额
        hpAmt: "0.00", // 横排交易金额 直属下级用户交易金额
        teamAmt: "0.00" // 团队交易金额
      }
    };
  },
  created() {
    getPosType().then(res => {
      // console.log('res = ', res)
      res.data.map(item => {
        this.selectPosType.push(item.name);
        this.selectPosTypeId.push(item.id);
      });
      this.loadData();
    });
  },
  methods: {
    selectDate(type) {
      this.selectDateType = type;
      if (type === 0) {
        this.dateTxt = "选择开始时间";
      } else {
        this.dateTxt = "选择结束时间";
      }
      this.showDate = true;
    },
    onPosType(value, index) {
      console.log(index);
      console.log(value);
      if (this.startTime && !this.endTime) {
        Toast("请选择结束时间");
        this.showSelectType = false;
        return;
      }
      if (!this.startTime && this.endTime) {
        Toast("请选择开始时间");
        this.showSelectType = false;
        return;
      }
      this.selectPosIndex = index;
      this.showSelectType = false;
      this.loadData();
    },
    formatterDate(type, val) {
      if (type === "year") {
        return val + "年";
      }
      if (type === "month") {
        return val + "月";
      }
      if (type === "day") {
        return val + "日";
      }
      return val;
    },
    confirmDate(value) {
      console.log("value = ", value);
      console.log("dayjs = ", dayjs);
      console.log("currentDate = ", this.currentDate);
      if (this.selectDateType === 0) {
        this.startTime = dayjs(value).format("YYYY-MM-DD");
      } else {
        if (!this.startTime) {
          Toast("请选择开始时间");
          this.showDate = false;
          return;
        }
        this.endTime = dayjs(value).format("YYYY-MM-DD");
      }
      this.showDate = false;
      if(this.startTime && this.endTime) {
        this.loadData();
      }
    },
    loadData() {
      let params = {
        posType: this.selectPosTypeId[this.selectPosIndex],
        startTime: this.startTime ? this.startTime + " 00:00:00" : null,
        endTime: this.endTime ? this.endTime + " 23:59:59" : null
      };
      const toast = Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "加载中"
      });
      getAllData(params)
        .then(res => {
          console.log("res =", res);
          this.result = res.data;
          Toast.clear();
        })
        .catch(error => {
          Toast.fail("加载失败");
        });
    },
    goBack() {
      this.$router.goBack();
    }
  }
};
</script>
<style lang="scss">
.ddd {
  background: #fff;
}
.pdd15px {
  padding: 0 15px;
}

.total-amt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;

  .txt {
    font-size: 10px;
  }

  .amt {
    margin-top: 12px;
    font-size: 2.3rem;
  }
}
</style>
