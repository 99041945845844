<template>
  <div>{{msg}}</div>
</template>

<script>
import {getURL} from "@/api/digital";
// 数字魔方
export default {
  name: "Digital",
  data() {
    return {
      msg: "加载中..."
    }
  },
  created() {
    getURL().then(res => {
      // console.log('res =', res)
      if (res.code === -1) {
        this.msg = res.msg;
      } else {
        window.location.href = res.data;
      }
    })
  }
}
</script>

<style scoped>

</style>
